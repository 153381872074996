export default {
    data() {
        return {
            isLoading: false,
            response: [],
            stateLoaded: false,
        }
    },
    created() {
        // setTimeout(() => {
            this.stateLoaded = true;
            this.getData();
        // }, 500);
    },
    computed: {
        activeUser() {
            return this.$store.getters['auth/getAuthenticatedUser']
        },
        isUserApproved() {
            if (this.stateLoaded) {
                return this.activeUser.member_status === 'approved'
            }
        },
        myEvents() {
            let events = [];
            this.response.forEach(element => {
                const found = this.activeUser.my_attending_events.find((item) => item.occurence_id === element.occurence_id);
                if(found){
                    events.push(element);
                }
            });
            return events;
        }
    },
    methods: {
        getData() {
            this.isLoading = true
            let url = 'offers-history'
            this.$http
                .get(url)
                .then((response) => {
                    if (response.status == 200) {
                        this.response = response.data.response_data;
                        this.isLoading = false;
                        console.log(this.response);
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        goToEventDetail(eventObj) {
            eventObj.type = 'offers';
            // eventObj.offer_attributes = {
            //     promo_code: eventObj.promo_code,
            //     discount_value: `${eventObj.discount_value} ${eventObj.discount_unit}`,
            // }
            this.$router.push(`/offers/${eventObj.offer_attributes.offer_id}`)
        },
    }
}